.page_header {
  margin-bottom: 20px;
}

.page_header_title_container {
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_header_title {
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 15px;
}
