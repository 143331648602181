.sideBar > .ps-sidebar-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px 0;
  justify-content: space-between;
}

.side_bar_logo_container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.side_bar_settings_section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sideBar .page_footer,
.side_bar_settings_section {
  padding: 0 20px;
}

.side_bar_menu_arrow_button {
  position: fixed;
  z-index: 150;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);
  transition: left 300ms ease-in-out;
}

.side_bar_menu_arrow_icon {
  font-size: 30px;
  transition: transform 300ms ease-in-out;
}

@media (min-width: 426px ) and ( max-width: 1024px ) {
    .side_bar_settings_section {
    row-gap: 5px;
    flex-direction: column;
  }
}
